.extrabold{
    font-weight: 800;
}
.bold{
    font-weight: 700;
}
.semibold{
    font-weight: 600;
}
.medium{
    font-weight: 500;
}
.pointer{
    cursor: pointer;
}
.field{
    margin-bottom: 1.75rem;
}
.p-datatable .p-datatable-thead > tr > th {
    padding: 0.45rem 0.3rem;
    font-size: 10pt;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.3rem;
    font-size: 9pt;
}
.p-button.p-button-icon-only.p-button-rounded {
    height: 2rem;
}
.pi {
    font-size: .75rem;
}
.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.45625rem 0.4375rem;
}
.p-button.p-button-sm .p-button-icon {
    font-size: 0.785rem;
}
.p-button.p-button-icon-only {
    width: 2rem;
}
.prime-badge.status-success {
    background: #C8E6C9;
    color: #256029;
}
.prime-badge.status-warning {
    background: #FEEDAF;
    color: #8a5340;
}
.prime-badge.status-danger {
    background: #FFCDD2;
    color: #c63737;
}
.prime-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.ps__rail-y{
    height: 0px !important;
}
.ps__thumb-y{
    height: 0px !important;
}
#sidebarnav{
    overflow-x: scroll;
    height: 100%;
}
.sidebar-nav{
    height: 100%;
}
.movil-none{
      display: block;  
}
.p-datepicker {
    padding: 0.5rem;
    padding-top: 6.1rem;
}
.p-datepicker table {
    font-size: .8rem;
}
.swal2-container {
    z-index: 1200 !important;
}
.p-multiselect-label-container {
    width: inherit;
}

.p-multiselect {
 width: 100%;
}
@media (max-width: 767.98px)
{
    .movil-none{
        display: none;
    }
    .p-button.p-button-sm {
        font-size: 0.755rem;
    padding: 0.42475rem 0.475rem;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0.5rem 0.5rem;
        font-weight: 600;
        font-size: 10.5pt;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0.5rem 0.5rem;
        font-size: 10pt;
    }
    .p-button.p-button-icon-only.p-button-rounded {
        height: 2.1rem;
    }
    .p-button.p-button-icon-only {
        width: 2.1rem;

    }
    .card-body {
        flex: 1 1 auto;
        padding: 0.8rem;
    }
    .mptl-1{
       padding-left:  .15rem;
    }
    .mptr-1{
        padding-right:  .15rem;
     }
     .page-wrapper > .container-fluid {
        padding-left: .10rem;
        padding-right: .10rem;
    }
    .p-paginator {
        padding: 0.5rem 0.5rem;
        font-size: 10.5pt;
    }
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
        min-width: 2rem;
        height: 2rem;
        margin: 0.113rem;
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
        margin: 0.113rem;
    }
    .p-paginator .p-dropdown {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        height: 2rem;
    }
    .p-dropdown-label {
        font-size: 11pt;
        padding-top: 1px;
    }
    .p-dropdown .p-dropdown-trigger {
        width: 2rem;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .pi {
        font-size: .90rem;
    }
    
    .p-tabview .p-tabview-panels {
        padding: 0.55rem;
    }
}